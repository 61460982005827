<template>
    <div class="section">
        <header-page />
        <TableCustomer :get-data="getData" :get-detail="getDetail" :detail-result="detailResult" :result="result" @filterBranch="getFilter" />
        <ModalCustomer :get-data="getData" :result="result" :get-detail="getDetail" :detail-result="detailResult" />
    </div>
</template>

<script>
import HeaderPage from '@/components/HeaderPage.vue'
import TableCustomer from '@/components/Customer/TableCustomer.vue';
import ModalCustomer from '@/components/Customer/Modal/ModalCustomer.vue';

export default {
    title() {
        return 'Gudang'
    },
    components: {
        HeaderPage,
        TableCustomer,
        ModalCustomer
    },
    data() {
        return {
            filter: {
                search: '',
                per_page: 10,
                sort_type: 'asc',
            },
            currentPage: 1,
            isLoading: true,
            result: {},
            detailResult: {},
            validations: [],
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getFilter(value) {
            this.filter = value
        },
        async getData(page = 1) {
            this.isLoading = true
            const queryParams = this.filter
            queryParams.page = page
            this.$store.dispatch('customer/getData', { uuid: '', params: queryParams }).then(result => {
                this.isLoading = false
                this.result = result.data.data
                this.currentPage = result.data.data.current_page
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
        getDetail(id) {
            this.$store.dispatch('customer/getDetail', {
                uuid: id || '',
                params: '',
            }).then(result => {
                this.detailResult = result.data.data
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
    },
}
</script>